import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";

const fitAddon = new FitAddon();
const OPTIONS_TERM = {
  useStyle: true,
  screenKeys: true,
  cursorBlink: true,
  cols: 200,
  theme: {
    background: "black",
  },
};
const term = new Terminal();

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

const socket = new WebSocket("ws://127.0.0.1:8001/ws");

const TerminalComponent = () => {
  let curr_line = ""
  let entries = []


  const terminalRef = useRef();

  useEffect(() => {
    socket.onmessage = (event) => {
 //     console.log(event.data);
      term.write(event.data)
      return () =>{

        //close web
        socket.close();
      }
    };
  },[])

  useEffect(() => {

    console.log("Socker state" + " " + socket.readyState);
    if (!terminalRef.current) {
      return
    }
   // term.loadAddon(fitAddon);
    term.open(terminalRef.current);
    term.write('$ ')

    // socket.onopen = () => {
    //   socket.send("Here's some text that the server is urgently awaiting!");
    // };
    //socket.send("Here's some text that the server is urgently awaiting!");
    let curr_line = ""
    let data = {}
    console.log("current  line" + " " + curr_line)  
    term.onKey((e) => {
      console.log(e)
      if(e.domEvent.key === "Enter" ){
        console.log("Nextline")
        if (curr_line) {
          entries.push(curr_line);
          data = {
            kind: 'input',
            message: curr_line
          }
          socket.send(JSON.stringify(data))
          term.write("\r\n");
          curr_line = ""
          
//          term.prompt();
        }
      } else if (e.domEvent.key === "Backspace") {
        if (curr_line) {
          curr_line = curr_line.slice(0, curr_line.length - 1);
          term.write("\b \b");
        }
      } else if (e.key === "\u0003"){
          console.log("terminate command set")
          data = {
            kind: 'input',
            message: '\\u0003'
          }
          console.log(JSON.stringify(data))
          socket.send(JSON.stringify(data))
          term.write("^C")
      }
      else {
        curr_line += e.key;
        term.write(e.key);        
      }
    });
    // term.onKey((e) => {
    
    //   console.log(e.type)
    //   // //handle enter key press
    //   // if (e.keyCode === 13) {
    //   //   console.log("nextline")
    //   //   if(curr_line){
    //   //     entries.push(curr_line);
    //   //     term.write("\r\n");
    //   //     term.prompt();
    //   //   }
    //   // } else if(e.keyCode === 8){
    //   //   // Backspace
    //   //   if (curr_line) {
    //   //     curr_line = curr_line.slice(0, curr_line.length - 1);
    //   //     term.write("\b \b");
    //   //   }
    //   // } else {
    //   //   curr_line += e.key;
    //   //   term.write(e.key);
    //   // }
  
    // })

    return () => {};
  }, [terminalRef]);
  
  return (
    <div
      ref={terminalRef}
    ></div>
  );
};

export default function Project() {
  const [ podready, SetPodready] = useState(true)


  return (
    <>
      {podready ? 
      <TerminalComponent />
      : <div>Initialising pod</div>}
    </>
  );
}
